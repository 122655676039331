<template>
    <div class="AccountSettings">
        <div class="user_setting">
            <button class="mu-icon-button" @click="exit()">
                <i class="el-icon-back mu-icon"></i>
            </button>
            <span class="settings">账号设置</span>
        </div>
        <div class="info_top">
            <div class="head_icon" @click="dialogVisible=true">
                <img :src="imageUrl===null||imageUrl===''||imageUrl==='null'?'https://static.ibeisai.com/image/default_avatar.png':imageUrl" class="avatar">
            </div>
        </div>
        <div class="info_list">
            <div class="mu-list">
                <div class="mu-items-wrapper disabled">
                    <div class="mu-items show-left show-right">
                        <div class="mu-items-left">
                            <div>手机号</div> 
                        </div>
                        <div class="mu-items-content">
                            <div class="mu-items-title-row">
                                <div class="mu-items-title list_title list_text">
                                    {{phone}}
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mu-items-wrapper disabled">
                    <div class="mu-items show-left show-right">
                        <div class="mu-items-left">
                            <div>用户名</div> 
                        </div>
                        <div class="mu-items-content">
                            <div class="mu-items-title-row">
                                <div class="mu-items-title list_title list_text">
                                    {{userName}}
                                </div> 
                            </div>
                        </div>
                        <div class="mu-items-right">
                            <i class="iconfont icon-huabi edit" @click="exitNa()"></i>
                        </div>
                    </div>
                </div>
                <div class="mu-items-wrapper disabled">
                    <div class="mu-items show-left show-right">
                        <div class="mu-items-left">
                            <div>密码</div> 
                        </div>
                        <div class="mu-items-content">
                            <div class="mu-items-title-row">
                                <div class="mu-items-title list_title list_text">
                                    ********
                                </div> 
                            </div>
                        </div>
                        <div class="mu-items-right">
                            <i class="iconfont icon-huabi edit" @click="exitPass()"></i>
                        </div>
                    </div>
                </div>
                <div class="item-line"></div>
            </div>
        </div>
        <!-- 修改用户名以及修改密码弹窗 -->
        <div class="Usern_Popup">
            <el-dialog
                :visible.sync="dialogusername"
                :show-close="false"
                width="480px"
                :before-close="handleClose"
            >
                <div class="User_Div">
                    <h3>修改用户名</h3>
                    <div class="mu-text-field has-label no-empty-state edit_name">
                        <div class="mu-text-field-content">
                            <div class="mu-text-field-label" :class="inputs==''&&focunum==0?'float':''">用户名</div>
                            <el-input v-model="inputs" size="small" @focus="focus" @blur="blur"></el-input>
                            <div><hr class="mu-text-field-line"> <hr class="mu-text-field-focus-line" :class="focunum==1?'focues':''"></div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <el-button type="info" @click="cancel()">取 消</el-button>
                        <el-button v-if="inputs==''" type="info" disabled>确 定</el-button>
                        <el-button v-else type="primary" @click="docadd()">确 定</el-button>
                    </div>
                </div>
            </el-dialog>
            <el-dialog
                :visible.sync="dialogPassword"
                :show-close="false"
                width="480px"
                :before-close="handleClose"
            >
                <div class="user_word">
                    <h3>修改密码</h3>
                    <div class="user_word_div">
                        <div class="mu-text-field has-label no-empty-state edit_name">
                            <div class="mu-text-field-content">
                                <div class="mu-text-field-label" :class="oldpw==''&& focunum1==0?'float':''">原登录密码</div>
                                <el-input v-model="oldpw" type="password" size="small" @focus="focus1" @blur="blur1"></el-input>
                                <div><hr class="mu-text-field-line"> <hr class="mu-text-field-focus-line" :class="focunum1==1?'focues':''"></div>
                            </div>
                        </div>
                        <div class="mu-text-field has-label no-empty-state edit_name">
                            <div class="mu-text-field-content">
                                <div class="mu-text-field-label" :class="newpw==''&& focunum2==0?'float':''">请输入新密码</div>
                                <el-input v-model="newpw" type="password" size="small" @focus="focus2" @blur="blur2"></el-input>
                                <div><hr class="mu-text-field-line"> <hr class="mu-text-field-focus-line" :class="focunum2==1?'focues':''"></div>
                            </div>
                        </div>
                        <div class="mu-text-field has-label no-empty-state edit_name">
                            <div class="mu-text-field-content">
                                <div class="mu-text-field-label" :class="newpw1==''&& focunum3==0?'float':''">再次输入新密码</div>
                                <el-input v-model="newpw1" type="password" size="small" @focus="focus3" @blur="blur3"></el-input>
                                <div><hr class="mu-text-field-line"> <hr class="mu-text-field-focus-line" :class="focunum3==1?'focues':''"></div>
                            </div>
                        </div>
                        <div class="hint-wrapper">
                            <div class="mu-text-field-help">
                                {{msg}}
                            </div>
                        </div>
                        <div class="btn-wrapper">
                            <el-button type="info" @click="cancel()">取 消</el-button>
                            <el-button v-if="oldpw=='' || newpw=='' || newpw1==''" type="info" disabled>确 定</el-button>
                            <el-button v-else type="primary" @click="passodd()">确 定</el-button>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <!-- 弹窗 -->
        <div class="member_Popup">
            <el-dialog
                title="编辑头像"
                :visible.sync="dialogVisible"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                width="600px"
            >
                <div style="display: flex" class="avatar">
                <div class="avatar-left">
                    <div v-show="!options.img">
                        <el-upload
                            ref="upload"
                            :action="uploadimage"
                            style="text-align: center;margin-bottom: 24px"
                            :on-change="uploads"
                            accept="image/png, image/jpeg, image/jpg"
                            :show-file-list="false"
                            :auto-upload="false">
                            <el-button slot="trigger" size="small" type="primary" ref="uploadBtn">选择图片</el-button>
                        </el-upload>
                    <div>支持jpg、png格式的图片，大小不超过3M</div>
                    </div>
                    <div v-show="options.img" class="avatar-left-crop">
                        <vueCropper
                            class="crop-box"
                            ref="cropper"
                            :img="options.img"
                            :autoCrop="options.autoCrop"
                            :canScale="options.canScale"
                            :fixedBox="options.fixedBox"
                            :canMoveBox="options.canMoveBox"
                            :autoCropWidth="options.autoCropWidth"
                            :autoCropHeight="options.autoCropHeight"
                            :centerBox="options.centerBox"
                            @realTime="realTime">
                        </vueCropper>
                        <p class="avatar-left-p">鼠标滚轮缩放控制图片显示大小，鼠标拖拽调整显示位置</p>
                    </div>
                </div>
                <div class="avatar-right">
                    <div class="avatar-right-div" v-for="(item,index) in previewsDiv" :key="index" :style="item.style">
                    <div v-show="options.img" :class="previews.div" class="avatar-right-previews" :style="item.zoomStyle">
                        <img :src="previews.url" :style="previews.img">
                    </div>
                    </div>
                    <div class="avatar-right-text">
                    <el-button v-if="options.img" type="text" @click="uploadPreviews">重新上传</el-button>
                    <span v-else>预览</span>
                    </div>
                </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog">取 消</el-button>
                    <el-button type="primary" @click="getCrop">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            phone:'',
            uploadimage:'',
            userName:'',
            imageUrl:'',
            inputs:'',
            oldpw:'',
            newpw:'',
            newpw1:'',
            focunum:0,
            focunum1:0,
            focunum2:0,
            focunum3:0,
            dialogVisible:false,
            dialogusername:false,
            dialogPassword:false,
            options: {
                img: '',  //原图文件
                autoCrop: true,  //默认生成截图框
                fixedBox: true,  //固定截图框大小
                canMoveBox: false,    //截图框不能拖动
                autoCropWidth: 200,  //截图框宽度
                autoCropHeight: 200, //截图框高度
                centerBox: true,    //截图框被限制在图片里面
                canScale: true, //图片是否允许滚轮缩放
            },
            //实时预览图数据
            previews: {},
            previewsDiv: [
                //108px 预览样式
                {
                    style: {
                    width: '108px',
                    height: '108px',
                    margin: '0 auto'
                    },
                    zoomStyle: {
                        transform:'scale(0.54,0.54)',
                        transformOrigin:'top left',
                    }
                },
                //68px 预览样式
                {
                    style: {
                    width: '68px',
                    height: '68px',
                    margin: '27px auto'
                    },
                    zoomStyle: {
                        transform:'scale(0.34,0.34)',
                        transformOrigin:'top left',
                    }
                },
                //48px 预览样式
                {
                    style: {
                    width: '48px',
                    height: '48px',
                    margin: '0 auto'
                    },
                    zoomStyle: {
                        transform:'scale(0.24,0.24)',
                        transformOrigin:'top left',
                    }
                }
            ],
            msg:'',
        }
    },
    methods: {
        getapp(){
            this.uploadimage = this.$store.state.uploadimage
            this.imageUrl = this.$store.state.user_avatar
            this.userName = this.$store.state.user_name
            this.phone = this.$store.state.phone
        },
         //读取原图
        uploads(file) {
            const isIMAGE = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
            const isLt3M = file.raw.size / 1024 / 1024 < 3;
            if (!isIMAGE) {
                this.$message({
                    showClose: true,
                    message: '请选择 jpg、png 格式的图片！',
                    type: 'error',  //提示类型
                });
                return false;
            }
            if (!isLt3M) {
                this.$message({
                    showClose: true,
                    message: '上传图片大小不能超过 3MB',
                    type: 'error',  //提示类型
                });
                return false;
            }
            let data = window.URL.createObjectURL(new Blob([file.raw]));
            this.fileName = file.name
            this.options.img = data;
        },
        cancel(){
            this.dialogusername = false
            this.dialogPassword = false
            this.oldpw = ''
            this.newpw = ''
            this.newpw1 = ''
        },
        // 修改用户名弹窗
        exitNa(){
            this.inputs = this.userName
            this.dialogusername = true
        },
        // 修改密码弹窗
        exitPass(){
            this.dialogPassword = true
        },
        //修改密码
        passodd(){
            if(this.newpw1!==this.newpw){
                this.msg = '🙄 两次输入的密码不一致'
            }else{
                this.axios.changepw({
                    token:this.$store.state.token,
                    oldpw:this.oldpw,
                    newpw:this.newpw
                }).then(res=>{
                    if(res.data.code==0){
                        this.$message.success('密码修改成功')
                        this.dialogPassword = false
                        this.getapp()
                    }else{
                        this.msg = res.data.message
                    }
                }).catch(err=>{

                })
            }
        },
        //修改用户名
        docadd(){
            this.axios.userupdate({
                token:this.$store.state.token,
                name:this.inputs,
                avatar:this.$store.state.user_avatar
            }).then(res=>{
                if(res.data.code==0){
                    this.$store.dispatch("user_name",res.data.data.user_name)
                    this.dialogusername = false
                    this.getapp()
                }else{
                    this.$message.info(res.data.message)
                }
            }).catch(err=>{

            })
        },
        //实时预览数据
        realTime(data) {
            this.previews = data
        },
        //获取截图信息
        getCrop() {
            // 获取截图的 base64 数据
            let formData = new FormData();
            this.$refs.cropper.getCropBlob((data) => {
                formData.append("file", data,this.fileName);
                axios.post(this.uploadimage,formData).then(res=>{
                    if(res.data.code==0){
                        this.axios.userupdate({
                            token:this.$store.state.token,
                            avatar:res.data.data,
                            name:this.$store.state.user_name
                        }).then(res=>{
                            if(res.data.code==0){
                                this.$store.dispatch("user_avatar",res.data.data.user_avatar)
                                this.dialogVisible = false
                                this.getapp()
                            }
                        }).catch(err=>{

                        })
                    }
                }).catch(err=>{

                })
                // this.imageUrl = data
                // this.dialogVisible = false
            })
        },
        //重新上传
        uploadPreviews() {
            this.$refs.uploadBtn.$el.click()
        },
        //关闭弹框
        closeDialog() {
            this.dialogVisible = false
            //重置 data 数据。(Object.assign是对象深复制  this.$data是组件内的数据对象 this.$options.data()是原始的数据)
            Object.assign(this.$data, this.$options.data())
            this.imageUrl = this.$store.state.user_avatar
        },
        // 获取焦点
        focus(){
            this.focunum = 1
        },
        // 失去焦点
        blur(){
            this.focunum = 0
        },
        // 获取焦点
        focus1(){
            this.focunum1 = 1
            this.msg = ''
        },
        // 失去焦点
        blur1(){
            this.focunum1 = 0
        },
        // 获取焦点
        focus2(){
            this.focunum2 = 1
            this.msg = ''
        },
        // 失去焦点
        blur2(){
            this.focunum2 = 0
        },
        // 获取焦点
        focus3(){
            this.focunum3 = 1
            this.msg = ''
        },
        // 失去焦点
        blur3(){
            this.focunum3 = 0
        },
        exit(){
            this.$router.back(-1)
        },
        handleClose(done){

        }
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/AccountSettings.css';
</style>
<style>
.Usern_Popup .el-dialog__header{
    padding: 0;
}
.Usern_Popup .el-dialog__body{
    padding: 0;
}
.Usern_Popup .User_Div h3 {
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    font-weight: bold;
    padding-bottom: 32px;
    margin: 0;
    color: #222222;
}
.mu-text-field-content .el-input__inner{
    border: none;
    padding: 0;
}
/* 编辑头像 */
.member_Popup .el-dialog__header {
    padding: 24px 0 11px 28px;
}
.member_Popup .el-dialog__title {
    color: #333333;
}
.member_Popup .el-dialog__body {
    padding: 0 28px;
}
.member_Popup .el-dialog__footer {
    padding: 20px 28px;
}
.member_Popup .el-button {
    width: 145px;
}
.member_Popup .avatar {
    display: flex;
}
.member_Popup .avatar-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      height: 400px;
      background-color: #F0F2F5;
      margin-right: 10px;
      border-radius: 4px;
}
.member_Popup .avatar-left-crop {
    width: 400px;
    height: 400px;
    position: relative;
}
.member_Popup .crop-box {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden
}
.member_Popup .avatar-left-p {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 20px;
    color: #ffffff;
    font-size: 14px;
}
.member_Popup .avatar-right {
      width: 150px;
      height: 400px;
      background-color: #F0F2F5;
      border-radius: 4px;
      padding: 16px 0;
      box-sizing: border-box;
}
.member_Popup .avatar-right-div {
    border: 3px solid #ffffff;
    border-radius: 50%;
    }

.member_Popup .avatar-right-previews {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}
.member_Popup .avatar-right-text {
    text-align: center;
    margin-top: 50px;
    font-size: 14px;
}
.member_Popup .avatar-right-text span {
    color: #666666;
}
</style>